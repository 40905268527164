// extracted by mini-css-extract-plugin
export var websiteRedesignCardBusinessSection = "n_bs";
export var websiteRedesignCaseSection = "n_bD";
export var websiteRedesignExpertiseSection = "n_bx";
export var websiteRedesignIndustriesSection = "n_bt";
export var websiteRedesignPracticesSection = "n_bz";
export var websiteRedesignPreferencesSection = "n_bw";
export var websiteRedesignPrimeSection = "n_bq";
export var websiteRedesignProcessSection = "n_bv";
export var websiteRedesignProjLogoSection = "n_bB";
export var websiteRedesignServicesSection = "n_br";
export var websiteRedesignTestimonialPost = "n_by";
export var websiteRedesignWebDesignCasesSection = "n_bC";